import React from "react"
import Layout from "../../components/layout"

export default function VueStoreFront() {
  return (
    <Layout>
      <h1>VueStoreFront</h1>
    </Layout>
  )
}
